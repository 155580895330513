import { http } from '@/helpers/http'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import type {
  TDraft,
  TDraftImportType,
  TDraftSaveResponse,
  TDraftsPagination,
  TDraftType,
  TEnhanceSuccessResponse,
  TErrorResponse,
  TModerationErrorResponse,
  TSortOptions,
  TUserSortOptions,
} from '@/types/drafts'
import { track } from '@/helpers/mixpanelDes'
import { detectRTL } from '@/helpers/rtl'
import { ElementSplitter } from '@/helpers/elementSplitter'

export const useDraftsStore = defineStore('drafts', () => {
  const labels: { [key: string]: string } = {
    // types
    'from-blog': 'Import from Blog post or URL',
    'from-docx': 'Import from Word DOCX',
    'from-gdoc': 'Import from Google Doc',
    'from-video': 'Import from Video',
    'from-template': 'Start from template',
    'merge-drafts': 'Merge docs',
    'pdf-to-flipbook': 'PDF',
    'from-podcast': 'Import from podcast',
    'from-pdf': 'Import from PDF',
    'from-scratch': 'Start from scratch',
    'from-wordgenie': 'Generate with Wordgenie',
    'from-file': 'Upload file/Insert link',

    //subtypes
    'audio': 'Import from podcast',
    'youtube': 'Import from YouTube',
    'vimeo': 'Import from Vimeo',
    'dropbox': 'Import a video on Dropbox',
    'wistia': 'Import a video on Wistia',
    'video': 'Import from video',
  }

  const drafts = ref<TDraft[]>([])
  const draft = ref<Partial<TDraft> | null>(null)
  const draftsPage = ref<number>(1)
  const draftsIdsWithoutThumbnails = ref<number[]>([])
  const draftsPagination = ref<TDraftsPagination | null>(null)
  const draftsSortBy = ref<string>('newest')
  const sortOptions = ref<TUserSortOptions>({})
  const user = useUserStore()
  const router = useRouter()
  const generateRunning = ref<boolean>(false)
  const generateTimeout = ref<NodeJS.Timeout | null>(null)
  const pageLimit = ref<number>(1000)
  const enhancing = ref<boolean>(false)
  const enhancedVersion = ref<'original' | 'enhanced'>('original')
  const lastFetchInfo = {
    type: 'all,',
    query: '',
    sortBy: '',
    nextPage: false,
  }

  function getLabel(type: string): string {
    return labels[type] || ''
  }

  async function checkThumbnail() {
    // const draft = drafts.value.find((draft) => !draft.thumbnail && draft.type === 'Standard')
    const id = draftsIdsWithoutThumbnails.value.length > 0 ? draftsIdsWithoutThumbnails.value[0] : null
    if (!id) {
      return
    }

    if (draftsIdsWithoutThumbnails.value.length > 0) {
      draftsIdsWithoutThumbnails.value.shift()
    }

    if (router.currentRoute.value.name === 'drafts' && generateRunning.value === true) {
      await generateThumbnail(id)
      generateTimeout.value = setTimeout(() => {
        checkThumbnail()
      }, 1000)
    } else {
      generateRunning.value = false
    }
  }

  async function refresh(): Promise<void> {
    return await fetch('refresh-current')
  }

  async function fetch(type: string = 'all', nextPage: boolean = false, sortBy: string = '', query: string = '') {
    if (type === 'refresh-current') {
      type = lastFetchInfo.type
      nextPage = lastFetchInfo.nextPage
      sortBy = lastFetchInfo.sortBy
      query = lastFetchInfo.query
    } else {
      lastFetchInfo.type = type
      lastFetchInfo.nextPage = nextPage
      lastFetchInfo.sortBy = sortBy
      lastFetchInfo.query = query
    }
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value
    enhancedVersion.value = 'original'

    if (sortBy !== '') {
      draftsSortBy.value = sortBy
      if (!sortOptions.value[user.user.id]) {
        sortOptions.value[user.user.id] = <TSortOptions>{}
      }
      sortOptions.value[user.user.id].drafts = sortBy
    } else {
      draftsSortBy.value = sortOptions.value[user.user.id]?.drafts || 'newest'
    }

    //in case of search query present, set big limit. if no query is present, set 30 portion
    const limit: number = query === '' ? 30 : pageLimit.value

    if (nextPage === false) {
      draftsPage.value = 1
    }

    const res = await http.post<{
      results: TDraft[]
      pagination: TDraftsPagination
    }>(`/get_drafts/${draftsPage.value}/${type}/${draftsSortBy.value}/${limit}`, {
      query: query,
    })

    if (nextPage === false) {
      drafts.value = res?.results ?? []
    } else {
      drafts.value = drafts.value.concat(res?.results ?? [])
    }

    if (res?.pagination) {
      draftsPagination.value = res.pagination
    }
    if (['all', 'standard', 'transcriptions', 'audiobooks'].includes(type)) {
      draftsIdsWithoutThumbnails.value = drafts.value
        .filter(
          (item) =>
            !item.thumbnail &&
            (item.type === 'Standard' ||
              item.type === 'Audio' ||
              item.type === 'AudioBook' ||
              (item.type === 'TemiJob' && item.status === 'Transcribed')),
        )
        .map((item) => item.id)
    } else {
      generateRunning.value = false
    }

    if (nextPage === false) {
      generateRunning.value = true
      if (generateTimeout.value) {
        clearTimeout(generateTimeout.value)
      }
      checkThumbnail()
    }
  }

  async function fetchNext(type: string) {
    draftsPage.value++
    const result = await fetch(type, true)
    if (generateTimeout.value) {
      clearTimeout(generateTimeout.value)
    }
    checkThumbnail()
    return result
  }

  async function generateThumbnail(draftId: number) {
    const result = await http.get<{
      thumbnail: string
    }>(`/generate_draft_thumbnail/${draftId}`)
    if (result) {
      // find thumbnail by id in drafts and set thumbnail
      const draft = drafts.value.find((draft) => draft.id === draftId)
      if (draft) {
        draft.thumbnail = result.thumbnail
      }
    }
  }

  async function storeUnsavedContent(id: number, content: string): Promise<void> {
    await http.post('/storeUnsavedContent', { id, content }, true)
  }

  async function save(
    id: number | null,
    title: string,
    content: string,
    url: string,
    importType?: TDraftImportType | string | null,
    importSource?: string | null,
    tags?: number[],
    type?: TDraftType,
  ) {
    const res = await http.post<TDraftSaveResponse>(`/save_draft`, {
      articleTitle: title,
      articleUrl: url,
      content,
      draftName: title,
      rtl: detectRTL(content),
      ...(importType && { importType: validSource(importType) }),
      ...(id && { id }),
      ...(importSource && { importSource }),
      ...(tags && { tags }),
      ...(type && { type }),
      enhancedVersion: draft.value?.enhanced_version || false,
    })
    if (res.status === 'success') {
      // generate thumbnail without await since it's not crucial after update
      generateThumbnail(res.id)
      return res.id
    }
    throw res.message ?? 'Doc saving error'
  }

  function validSource(importType?: TDraftImportType | string): TDraftImportType | null {
    if (!importType) return null

    if (typeof importType === 'string') {
      try {
        const type = <TDraftImportType>JSON.parse(importType)
        if (!type.type) return null
        return type
      } catch {
        return null
      }
    }

    return importType
  }

  async function rename(draftId: number, draftName: string) {
    try {
      await http.post('/rename_draft', { draftId, draftName }, true)
      track('draft-renamed', {
        name: draftName,
      })
      return true
    } catch {
      track('draft-rename-failed', {
        name: draftName,
      })
      return false
    }
  }

  async function duplicate(draft: TDraft, name: string) {
    const res = await http.post<TDraft>('/duplicate_draft/', {
      id: draft.id,
      name,
    })
    if (!res) {
      track('draft-duplicate-failed', {
        name: draft.name,
      })
      throw new Error(`Failed to duplicate draft`)
    }
    track('draft-duplicated', {
      name: draft.name,
    })
    await refresh()
    return res
  }

  async function deleteDraft(draftId: number) {
    return await http.post('/delete_draft', { id: draftId })
  }

  async function remove(draft: TDraft) {
    const index = drafts.value.findIndex((item) => draft.id === item.id)
    if (index >= 0) {
      drafts.value.splice(index, 1)
      if (draftsPagination.value) {
        draftsPagination.value.total--
      }
      const res = await deleteDraft(draft.id)
      if (!res) {
        track('draft-delete-failed', {
          name: draft.name,
        })
        throw new Error(`Failed to delete project`)
      }
      track('draft-deleted', {
        name: draft.name,
      })
    }
  }

  async function removeDrafts(selectedDrafts: TDraft[]) {
    const ids = selectedDrafts.map((d) => d.id)
    const res = await http.post('/delete_drafts', { ids })
    if (!res) {
      track('drafts-delete-failed', {
        count: ids.length,
      })
      throw new Error(`Failed to delete docs`)
    }
    track('drafts-deleted', {
      count: ids.length,
    })
    await refresh()
  }

  async function mergeDrafts(drafts: TDraft[]): Promise<number> {
    const ids = drafts.map((d) => d.id)
    const result = await http.post<any>('/merge_drafts', { ids })
    if (result.status !== 'success') {
      track('drafts-merge-failed', {
        count: ids.length,
      })
      throw new Error('Error merging docs!')
    }
    track('drafts-merged', {
      count: ids.length,
    })
    return result.draftId
  }

  async function get(id: number, enhanced = false) {
    try {
      const res = await http.post<TDraft>('/get_draft', { id, enhanced })
      draft.value = res

      return res
    } catch {
      draft.value = null
    }
  }

  function getRouteForType(type: string): string {
    switch (type) {
      case 'Standard':
      case 'ebook':
      case 'e-Book': {
        return `/docs/ebook`
      }
      case 'Audio':
      case 'transcription':
      case 'TemiJob': {
        return `/docs/transcription`
      }
      case 'AudioBook':
      case 'audiobook': {
        return `/docs/audiobook`
      }
      default:
        throw new Error('[not handled] edit draft ' + type)
    }
  }

  async function goToEditDraft(id: number, type: string, from: string): Promise<void> {
    const route = getRouteForType(type)
    track('draft-edit', {
      type: type,
      from: from,
    })
    await router.push(`${route}/${id}`)
  }

  async function applyTags(draftId: number, tags: number[]): Promise<boolean> {
    try {
      const res = await http.post('/apply_tags_to_draft/' + draftId, { tags })

      if (!res) {
        throw new Error(`Failed to apply tags`)
      }

      return true
    } catch {
      return false
    }
  }

  async function enhanceCopy(draftId: number): Promise<boolean> {
    try {
      const res = await http.post('/ai/enhance/copy-draft', { id: draftId })

      if (!res) {
        throw new Error(`Failed to start enhance`)
      }
      return true
    } catch {
      return false
    }
  }

  async function enhance(draftId: number, reword = false, images = false): Promise<boolean> {
    try {
      const res = await http.post('/ai/enhance/draft', { id: draftId, reword, images })

      if (!res) {
        throw new Error(`Failed to start enhance`)
      }
      return true
    } catch {
      return false
    }
  }

  async function applyEnhance(draftId: number): Promise<boolean> {
    try {
      const res = await http.post('/ai/enhance/apply-draft', { id: draftId })

      if (!res) {
        throw new Error(`Failed to apply enhanced version`)
      }
      return true
    } catch {
      return false
    }
  }

  function getSectionInfo(enhancedContent: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(enhancedContent, 'text/html')
    const headings = doc.querySelectorAll('h1, h2, h3, h4, h5, h6')
    const headingTexts = Array.from(headings).map((heading) => heading.outerHTML.trim() || '')
    return headingTexts.join('\n ')
  }

  function getPrevSectionsInfo(sections: string[]): string {
    if (!sections.length) return ''
    return sections.map((s) => getSectionInfo(s)).join('\n')
  }

  async function enhanceSection(
    draftId: number,
    part: number,
    currentSections: string[],
    content: string,
    reword = false,
    images = false,
  ): Promise<{ success: boolean; message: string; enhancedContent: string }> {
    let success: boolean
    let message: string
    let enhancedContent: string = ''
    try {
      console.log('Enhancing section')
      console.log(content)
      const prevSectionInfo = getPrevSectionsInfo(currentSections)
      const res: TEnhanceSuccessResponse | TErrorResponse | TModerationErrorResponse = await http.post(
        '/ai/enhance/draft-section',
        {
          id: draftId,
          part,
          content,
          prevSectionInfo,
          reword,
          images,
        },
      )

      if (res.status === 'error') {
        success = false
        message = 'Document enhancing error'
      } else if (res.status === 'moderation_error') {
        success = false
        message = res.message
      } else {
        /* @ts-ignore */
        enhancedContent = res.result.content
        console.log(enhancedContent)
        success = true
        message = 'Document enhanced!'
      }
    } catch {
      console.log('Error enhancing section')
      success = false
      message = 'Document enhancing error'
    }
    return { success, message, enhancedContent }
  }

  function splitElement(element: HTMLElement, maxCharSection: number): void {
    if (element.outerHTML.length < maxCharSection) {
      return
    }
    const sp = new ElementSplitter()
    const okFunc = () => element.outerHTML.length < maxCharSection
    const res = sp.splitElement(element, element.parentElement, okFunc)
    if (res.second) {
      element.after(res.second)
    }
  }

  function splitDraftContent(content: string): string[] {
    const maxCharSection = 16384
    const parser = new DOMParser()
    const doc = parser.parseFromString(`<div class="section">${content}</div>`, 'text/html')
    let child: HTMLElement | null = doc.querySelector<HTMLElement>('.section') //.children[0] as HTMLElement
    const sections: string[] = []
    while (child) {
      splitElement(child, maxCharSection)
      sections.push(child.outerHTML)
      const next: Element | null = child.nextElementSibling
      if (!next) {
        break
      }
      if (!(next instanceof HTMLElement)) {
        continue
      }
      child = next
    }
    return sections
  }

  return {
    draft,
    drafts,
    draftsPage,
    draftsPagination,
    draftsSortBy,
    pageLimit,
    storeUnsavedContent,
    getLabel,
    fetch,
    fetchNext,
    refresh,
    save,
    rename,
    duplicate,
    remove,
    removeDrafts,
    deleteDraft,
    get,
    mergeDrafts,
    goToEditDraft,
    applyTags,
    enhance,
    enhanceSection,
    splitDraftContent,
    applyEnhance,
    enhancing,
    enhancedVersion,
    enhanceCopy,
  }
})
