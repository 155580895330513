<script setup lang="ts">
  import { useMenuStore } from '@/stores/menu'
  import { useSnackbarStore, type TSnackbarItem } from '@/stores/snackbar'

  const snack = useSnackbarStore()
  const menu = useMenuStore()
  const transitions = 'opacity-0 h-0 !py-0 !mt-0 !text-transparent -bottom-6'

  function icon(item: TSnackbarItem) {
    switch (item.type) {
      case 'danger':
        return 'fa-solid fa-circle-exclamation'
      case 'success':
        return 'fa-solid fa-circle-check'
      default:
        return 'fa-solid fa-circle-info'
    }
  }
</script>

<template>
  <div
    class="fixed bottom-6 left-1/2 z-50 text-white -translate-x-1/2 w-80 sm:w-96 flex flex-col transition-all"
    :class="{
      'panel-margin-1/2': menu.isOpen,
      'ml-16': !menu.isOpen,
    }"
  >
    <TransitionGroup :enter-from-class="transitions" :leave-to-class="transitions">
      <div
        v-for="item in snack.list"
        class="relative px-4 py-2 mt-4 rounded transition-all flex gap-4 items-center overflow-hidden"
        :class="{
          'bg-red-40': item.type === 'danger',
          'bg-blue-40': item.type === 'info',
          'bg-green-40': item.type === 'success',
          'bg-black-20': !item.type,
        }"
        :key="item.id"
      >
        <component v-if="item.component" :is="item.component"></component>
        <div class="shrink grow flex gap-2 items-center" v-else>
          <des-icon :icon="icon(item)" class="w-icon h-icon" />
          {{ item.message }}
        </div>
        <des-icon
          v-if="!item.noClose"
          icon="fa-light fa-times"
          class="w-icon h-icon cursor-pointer"
          @click="snack.remove(item.id)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>
