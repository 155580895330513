<script setup lang="ts">
  import { computed } from 'vue'
  import { useSearchStore } from '@/stores/search'
  import { getTimeDifference } from '@/helpers/date'
  import { useBrowserStore } from '@/stores/browser'
  import { useAudiobooksStore } from '@/stores/audiobooks'
  import { useFlipbooksStore } from '@/stores/flipbooks'
  import { useProjectsStore } from '@/stores/projects'
  import { useSnackbarStore } from '@/stores/snackbar'
  import { useStorage } from '@vueuse/core'
  import type { TCombinedSearchResult } from '@/types/search'
  import { useDraftsStore } from '@/stores/drafts'
  import { useRouter } from 'vue-router'

  const search = useSearchStore()
  const drafts = useDraftsStore()
  const snack = useSnackbarStore()
  const audiobooks = useAudiobooksStore()
  const flipbooks = useFlipbooksStore()
  const projects = useProjectsStore()
  const browser = useBrowserStore()
  const lastSearches = useStorage<string[]>(search.getLastSearchesUserLabel(), [])
  const router = useRouter()

  function displayType(item: TCombinedSearchResult): string {
    const type: string = item.result_type

    if (type === 'project') {
      return 'eBook'
    } else if (type === 'e-Book') {
      return 'eBook docs'
    } else if (type === 'audiobook' && item.slug === '') {
      return 'audiobook docs'
    } else if (type === 'transcription' || type === 'audio job') {
      return 'transcription docs'
    }

    return type.charAt(0).toLowerCase() + type.slice(1)
  }

  async function clickResult(item: TCombinedSearchResult) {
    const from = 'drafts-search'

    if (item.result_type === 'project') {
      projects.goToBuilderAndEdit(item.slug_id, item.slug)
    } else if (item.result_type === 'audiobook' && item.slug !== '') {
      if (audiobooks.isInProgress(item)) {
        snack.add('Processing is still in progress')
        return
      }
      const token = item.token ? item.token : ''
      const url = await audiobooks.getAudiobookPreviewUrl(item.id, token)
      await browser.openNewWindowTab(url)
    } else if (item.result_type === 'flipbook') {
      const token = item.token ? item.token : ''
      await browser.openNewWindowTab(await flipbooks.buildFlipbookUrl(item.id, token, 'FP'))
    } else if (item.result_type === 'eBook' || item.result_type === 'e-Book') {
      return drafts.goToEditDraft(item.id, item.result_type, from)
    } else if (item.result_type === 'transcription') {
      if (item?.status === 'In progress') {
        snack.add('The transcription is still in progress')
        return
      } else if (item?.status === 'Failed') {
        snack.add('Transcription failed', { type: 'danger' })
        return
      }
      return drafts.goToEditDraft(item.id, item.result_type, from)
    } else if (item.result_type === 'audiobook') {
      return drafts.goToEditDraft(item.id, item.result_type, from)
    } else if (item.result_type === 'audio job') {
      snack.add('Transcription is still in progress')
    } else if (item.result_type === 'landing-page') {
      search.resultsHidden = true
      return router.push(`/landing-pages/${item.id}`)
    }
  }

  function lastModified(updatedAt: string): string {
    return getTimeDifference(updatedAt)
  }

  function clickLastSearch(query: string) {
    search.searchAll(query, true)
    emit('queryChanged', query)
  }

  const lastSearchesReverse = computed(() => {
    return lastSearches?.value?.length ? [...lastSearches.value].reverse() : []
  })

  const emit = defineEmits<{
    queryChanged: [value: string]
  }>()
</script>

<template>
  <div
    v-if="search.results?.length"
    class="shadow-lg absolute top-10 -left-10 -right-10 md:left-0 md:right-0 min-h-10 rounded-lg border border-black-90 justify-start bg-white py-2"
  >
    <div v-if="lastSearches?.length && !search.recentSearchesHidden" class="text-black-60 text-xs my-2 mx-4 leading-4">
      Recent searches
    </div>
    <div class="px-2" v-if="!search.recentSearchesHidden">
      <div
        v-for="(item, index) in lastSearchesReverse"
        :key="index"
        class="px-2 py-3 flex gap-2 items-center cursor-pointer rounded hover:bg-white-gray"
        @click.stop="clickLastSearch(item)"
      >
        <des-icon icon="fa-light fa-clock" class="w-icon h-icon shrink-0 grow-0" />
        <div class="whitespace-nowrap text-ellipsis overflow-hidden shrink grow">{{ item }}</div>
      </div>
    </div>

    <div v-if="search.results?.length" class="text-black-60 text-xs my-2 mx-4 leading-4">Search results</div>
    <div class="px-2">
      <div
        v-for="(item, index) in search.results"
        :key="index"
        class="px-2 py-3 flex gap-2 items-center cursor-pointer rounded hover:bg-white-gray"
        @click.stop="clickResult(item)"
      >
        <img
          :src="item.thumbnail_url"
          class="rounded shrink-0 grow-0"
          :class="item.thumbnail_url.includes('/ico-from-') ? 'w-7 -mx-1' : 'w-5'"
        />

        <div class="flex flex-col grow shrink overflow-hidden">
          <div class="whitespace-nowrap overflow-hidden text-ellipsis">
            {{ item.name }}
          </div>
          <div class="text-black-60 text-xs">{{ displayType(item) }} &#8226; {{ lastModified(item.updated_at) }}</div>
        </div>
      </div>

      <div
        @click="search.showAllResults()"
        class="px-2 py-3 flex gap-2 items-center cursor-pointer rounded hover:bg-white-gray"
      >
        <des-icon icon="fa-light fa-search" class="w-icon h-icon shrink-0 grow-0" />
        All search results for '{{ search.searchQuery }}'
      </div>
    </div>
  </div>

  <div
    v-if="search.noSearchResults"
    class="absolute top-10 w-full h-96 rounded-lg border border-black-90 justify-center items-center bg-white text-blue-10 flex flex-col gap-4"
  >
    <des-icon icon="fa-light fa-search" class="w-14 h-14 text-black-90" />
    <div class="text-center">
      <div class="font-semibold mb-2">No results found</div>
      <div>Please try selecting different keywords or filters.</div>
    </div>
  </div>
</template>
