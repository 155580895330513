import { defineStore } from 'pinia'
import QRCode from 'qrcode'
import { http } from '@/helpers/http'
import { useUserStore } from '@/stores/user'
import { usePopupStore } from '@/stores/popup'
import { useLoaderStore } from '@/stores/loader'
import { useFlipbooksStore } from '@/stores/flipbooks'
import { useAudiobooksStore } from '@/stores/audiobooks'
import type { TQrCodeFormat } from '@/types/qrcode'
import type { TPdf, TGetPdfs, TRegenPdf } from '@/types/pdf'
import type { TFlipbook } from '@/types/flipbooks'
import type { TAudiobookPreview, TProjectAudiobook } from '@/types/audiobooks'

export const useQRCodeStore = defineStore('qr-code', () => {
  const popup = usePopupStore()
  const user = useUserStore()
  const loader = useLoaderStore()
  const flipbook = useFlipbooksStore()
  const audiobooks = useAudiobooksStore()

  async function handleNoPdf() {
    loader.hide()
    const result = await popup.confirm('Your project has no PDF published.', {
      content: `Would you like to publish the PDF? (Note: Projects larger than 60 pages may take a few minutes to produce)`,
      labelCancel: 'Cancel',
      labelConfirm: 'Confirm',
    })

    return result
  }

  async function attemptToGetFormats(projectId: number): Promise<TQrCodeFormat[]> {
    const pdfs: TPdf[] = await getPdfs(projectId)

    if (!pdfs.length || pdfs[0].url === '') {
      return []
    }

    return await getFormats(pdfs[0].url, projectId)
  }

  async function attemptToGetAudioFormats(project: TProjectAudiobook): Promise<TQrCodeFormat[]> {
    const prevData: TAudiobookPreview | null = await audiobooks.preview(project.id)
    const url: string = await audiobooks.getAudiobookPreviewUrl(project)

    const formats: TQrCodeFormat[] = []
    let tabCount = 1

    if (url?.length) {
      formats.push(await getFormatData('tab' + tabCount, 'Preview', window.location.origin + url))
      tabCount = tabCount + 1
    }

    if (prevData?.url?.length) {
      formats.push(await getFormatData('tab' + tabCount, 'Mp3', prevData.url))
    }

    return formats
  }

  async function getFormatData(name: string, label: string, url: string): Promise<TQrCodeFormat> {
    return { name, label, url, qrCodePng: await getQRCode(url, 'png'), qrCodeSvg: await getQRCode(url, 'svg') }
  }

  async function getFormats(pdf: string, projectId: number): Promise<TQrCodeFormat[]> {
    const pdfFormat: TQrCodeFormat = await getFormatData('tab1', 'PDF', pdf)
    const at = user.accountType

    const fetchFlipbook = at.flipbook_publish
      ? http.post<TFlipbook>('/create_flipbook', { projectId })
      : Promise.resolve(null)
    const fetchLiveProject = at.live_ebook_export
      ? http.post<{ url: string;status: string | null }>('/projects/share-url/1', { id: projectId })
      : Promise.resolve(null)
    const fetchEpub = at.ebook_export ? http.post('/epub/link/' + projectId, {}, true) : Promise.resolve(null)

    return await fetchAllFormats(fetchFlipbook, fetchLiveProject, fetchEpub, [pdfFormat])
  }

  async function fetchAllFormats(
    fetchFlipbook: Promise<TFlipbook | null>,
    fetchLiveProject: Promise<{ url: string } | null>,
    fetchEpub: Promise<string | null>,
    formats: [TQrCodeFormat],
  ): Promise<TQrCodeFormat[]> {
    await Promise.allSettled([fetchFlipbook, fetchLiveProject, fetchEpub])
      .then(async ([resFlipbook, resLiveProject, resEpub]) => {
        const dataFlipbook = resFlipbook.status === 'fulfilled' ? resFlipbook.value : null
        const dataLiveProject = resLiveProject.status === 'fulfilled' ? resLiveProject.value : null
        const dataEpub = resEpub.status === 'fulfilled' ? resEpub.value : null
        let tabCount = 2

        if (dataFlipbook?.flipbook) {
          const info = await flipbook.getFlipbookInfo(dataFlipbook)
          formats.push(await getFormatData('tab' + tabCount, 'Flipbook', info))
          tabCount++
        }

        if (dataLiveProject?.url) {
          formats.push(await getFormatData('tab' + tabCount, 'Live eBook', dataLiveProject.url))
          tabCount++
        }

        if (dataEpub) {
          formats.push(await getFormatData('tab' + tabCount, 'ePub', dataEpub))
        }
      })
      .catch((error) => {
        console.log('Error during fetch, getFormats', error)
      })

    return formats
  }

  async function regenPdf(projectId: number): Promise<TPdf[]> {
    loader.show('Regenerating pdf...')

    let toReturn: TPdf[] = []

    try {
      const data: TRegenPdf | null = await http.post<TRegenPdf>('/html2pdf/regen_pdf', { project_id: projectId })
      toReturn = data?.pdf ? [data.pdf] : []

      loader.hide()
    } catch (e) {
      popup.alert('Error regenerating PDF')
      loader.hide()
    }

    return toReturn
  }

  async function getPdfs(projectId: number): Promise<TPdf[]> {
    let toReturn: TPdf[] = []

    try {
      const data: TGetPdfs | null = await http.post<TGetPdfs>('/html2pdf/getpdfs', {
        project_id: projectId,
      })

      if (!data || (data.status && data.status !== 'success') || !data?.data?.pdfs?.length) {
        if (await handleNoPdf()) {
          toReturn = await regenPdf(projectId)
        } else {
          console.log('No PDF available. No regen chosen.')
        }
      } else {
        toReturn = data.data.pdfs
      }
    } catch (error) {
      popup.alert('Error regenerating PDF')
      console.log(error)
    }

    return toReturn
  }

  async function getQRCode(sourceUrl: string, format: string): Promise<string> {
    if (format === 'svg') {
      return await getSvgQrCode(sourceUrl)
    } else {
      return getPngQrCode(sourceUrl)
    }
  }

  async function getSvgQrCode(sourceUrl: string): Promise<string> {
    try {
      const url = await QRCode.toString(sourceUrl)
      return await svgStringToFile(url)
    } catch (error) {
      console.error(error)
      return ''
    }
  }

  function getPngQrCode(sourceUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      try {
        resolve(QRCode.toDataURL(sourceUrl))
      } catch (error) {
        reject(error)
      }
    })
  }

  async function svgStringToFile(svgString: string): Promise<string> {
    const blob = new Blob([svgString], { type: 'image/svg+xml' })
    const reader = new FileReader()

    return new Promise<string>((resolve, reject) => {
      reader.onload = function (event) {
        const dataUrl: string = event.target?.result ? event.target.result.toString() : ''
        resolve(dataUrl)
      }

      reader.onerror = function (event) {
        const err = event?.target?.error ? event.target.error : 'Error in svg to file'
        reject(err)
      }

      reader.readAsDataURL(blob)
    })
  }

  return { attemptToGetFormats, attemptToGetAudioFormats }
})
