import { defineStore } from 'pinia'
import type { TProjectsSection, TProject, TSortOptions, TUserSortOptions, TProjectsPagination } from '@/types/projects'
import type { TClient } from '@/types/agency'
import { computed, ref } from 'vue'
import { useLoaderStore } from '@/stores/loader'
import { useUserStore } from '@/stores/user'
import { useSnackbarStore } from './snackbar'
import { useMediaQuery, useStorage } from '@vueuse/core'
import { track } from '@/helpers/mixpanelDes'
import { http } from '@/helpers/http'

export const useProjectsStore = defineStore('projects', () => {
  const sections = ref<TProjectsSection[]>([])
  const sectionsSortBy = ref<string>('newest')
  const projects = ref<TProject[]>([])
  const myTemplates = ref<TProject[]>([])
  const projectsPage = ref<number>(1)
  const projectsPagination = ref<TProjectsPagination | null>(null)
  const projectsSortBy = ref<string>('newest')
  const projectToPublish = ref<TProject | null>(null)
  const section = ref<TProjectsSection | null>(null)
  const sortOptions = ref<TUserSortOptions>({})
  const rootProjectsCount = ref<number>(0)
  const user = useUserStore()
  const loader = useLoaderStore()
  const snackbar = useSnackbarStore()
  const myTemplatesSearch = ref<string>('')
  const myTemplatesSortOptions = [
    { label: 'Newest', name: 'newest', icon: 'fa-clock' },
    { label: 'Oldest', name: 'oldest', icon: 'fa-clock' },
    { label: 'A-Z', name: 'a-z', icon: 'fa-clock' },
    { label: 'Z-A', name: 'z-a', icon: 'fa-clock' },
  ]
  const myTemplatesSort = ref(myTemplatesSortOptions[0])

  async function fetchSections(sortBy: string = '') {
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value

    if (sortBy !== '') {
      sectionsSortBy.value = sortBy
      if (!sortOptions.value[user.user.id]) {
        sortOptions.value[user.user.id] = <TSortOptions>{}
      }
      sortOptions.value[user.user.id].folders = sortBy
    } else {
      sectionsSortBy.value = sortOptions.value[user.user.id]?.folders || 'newest'
    }

    const res = await fetch(`/projects_sections/list/${sectionsSortBy.value}`)
    if (!res.ok) {
      throw new Error(`Failed to fetch sections`)
    }
    const data = await res.json()
    sections.value = data
  }

  async function deleteSection(sectionId: number) {
    const res = await fetch('/projects_sections/remove', {
      method: 'POST',
      body: JSON.stringify({
        sectionId: sectionId,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to delete section`)
    }
    const data = await res.json()
    if (data.result === true) {
      const index = sections.value.findIndex((item) => item.id === sectionId)
      if (index >= 0) {
        sections.value.splice(index, 1)
      }
    }
  }

  async function deleteSections(sectionsToDelete: TProjectsSection[]) {
    const res = await fetch('/projects_sections/remove', {
      method: 'POST',
      body: JSON.stringify({
        sectionId: sectionsToDelete.map((item: TProjectsSection) => {
          return item.id
        }),
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to delete section`)
    }
    const data = await res.json()
    if (data.result === true) {
      sectionsToDelete.forEach((section) => {
        const index = sections.value.findIndex((item) => item.id === section.id)
        if (index >= 0) {
          sections.value.splice(index, 1)
        }
      })
    }
  }

  async function renameSection(sectionId: number, name: string) {
    const res = await fetch('/projects_sections/rename', {
      method: 'POST',
      body: JSON.stringify({
        sectionId: sectionId,
        name,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to rename section`)
    }
  }

  async function fetchProjects(
    sectionId: number = 0,
    nextPage: boolean = false,
    sortBy: string = '',
    query: string = '',
  ) {
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value

    if (sortBy !== '') {
      projectsSortBy.value = sortBy
      if (!sortOptions.value[user.user.id]) {
        sortOptions.value[user.user.id] = <TSortOptions>{}
      }
      sortOptions.value[user.user.id].projects = sortBy
    } else {
      projectsSortBy.value = sortOptions.value[user.user.id]?.projects || (sectionId > 0 ? 'custom' : 'newest')
    }

    if (nextPage === false) {
      projectsPage.value = 1
      projects.value = []
      section.value = null
      projectsPagination.value = null
    }

    if (projectsSortBy.value === 'custom' && sectionId === 0) {
      projectsSortBy.value = 'newest'
    }

    const res = await fetch(`/projects_sections/${sectionId}`, {
      method: 'POST',
      body: JSON.stringify({
        sortBy: projectsSortBy.value,
        page: projectsPage.value,
        query: query,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to fetch projects`)
    }
    const data = await res.json()

    if (projectsPage.value > 1) {
      projects.value = projects.value.concat(data.results)
    } else {
      projects.value = data.results
    }
    if (data.section) {
      section.value = data.section
    }
    rootProjectsCount.value = data.root_count || 0

    projectsPagination.value = data.pagination
  }

  async function fetchProject(slugId: string): Promise<TProject | null> {
    try {
      const res = await http.get<TProject | null>(`/projects/${slugId}?id=true`)
      if (!res) throw new Error(`Failed to fetch project`)
      return res
    } catch {
      return null
    }
  }

  async function fetchRecentProjects(limit: number = 14) {
    const res = await fetch(`/projects/limit/${limit}`)
    if (!res.ok) {
      throw new Error(`Failed to fetch projects`)
    }
    const data = await res.json()
    projects.value = data
  }

  async function fetchMyTemplates() {
    const res = await fetch(`/pr-templates-new/`)
    if (!res.ok) {
      throw new Error(`Failed to fetch projects`)
    }
    const data = await res.json()
    myTemplates.value = data
  }

  async function fetchNextProjects(sectionId: number) {
    projectsPage.value++
    return await fetchProjects(sectionId, true)
  }

  async function duplicateProject(project: TProject, copyName: string) {
    const res = await fetch('/duplicate_project/', {
      method: 'POST',

      body: JSON.stringify({
        newProjectName: copyName,
        oldProjectName: project.name,
        projectId: project.id,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to duplicate project`)
    }
    const data = await res.json()
    if (data.status === 'success') {
      fetchProjects(section?.value?.id ? section.value.id : 0, false)
    }
    return data
  }

  function findIndex(project: TProject, proTemplateType?: boolean): number {
    const toSearch = proTemplateType ? myTemplates : projects
    return toSearch.value.findIndex((item) => {
      return project.id === item.id
    })
  }

  async function deleteProject(project: TProject, proTemplateType?: boolean) {
    const index = findIndex(project, proTemplateType)

    if (index >= 0) {
      if (proTemplateType) {
        myTemplates.value.splice(index, 1)
      } else {
        projects.value.splice(index, 1)
      }
      if (projectsPagination.value && !proTemplateType) {
        projectsPagination.value.total--
      }
      const res = await fetch(`/projects/${project.id}`, {
        method: 'DELETE',
      })
      if (!res.ok) {
        throw new Error(`Failed to delete project`)
      }
      track('deleted-project', {
        name: project.name,
        id: project.id,
      })
    }

    if (!proTemplateType) {
      fetchSections()
    }
  }

  async function deleteProjects(projectsToDelete: TProject[]) {
    loader.show('Deleting projects...')
    const ids = projectsToDelete.map((item) => {
      return item.id
    })
    if (ids.length > 0) {
      const res = await fetch('/projects/delete', {
        method: 'POST',
        body: JSON.stringify({
          ids,
        }),
      })
      if (!res.ok) {
        throw new Error(`Failed to delete projects`)
      }
      const data = await res.json()
      if (data.result === true) {
        for (let i = 0; i < ids.length; i++) {
          const index = projects.value.findIndex((item) => {
            return ids[i] === item.id
          })
          if (index >= 0) {
            projects.value.splice(index, 1)
            if (projectsPagination.value) {
              projectsPagination.value.total--
            }
          }
        }
      }
    }
    loader.hide()
  }

  async function renameProject(id: number, name: string) {
    const res = await fetch('/projects/rename_project', {
      method: 'POST',
      body: JSON.stringify({
        id,
        name,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to rename project`)
    }
    const data = await res.json()
    return data
  }

  async function createFolder(name: string) {
    const res = await fetch('/projects_sections/add', {
      method: 'POST',
      body: JSON.stringify({
        id: 0,
        name,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to create folder`)
    }
    fetchSections()
    const data = await res.json()
    return data
  }

  async function moveToFolder(projectId: number, sectionId: number) {
    const res = await fetch('/projects_sections/move', {
      method: 'POST',
      body: JSON.stringify({
        projectId,
        sectionId,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to move folder`)
    }
    const data = await res.json()
    if (data.result === true && sectionId != section.value?.id) {
      const index = projects.value.findIndex((item) => {
        return item.id === projectId
      })
      if (projectsPagination.value) {
        projectsPagination.value.total--
      }
      projects.value.splice(index, 1)
    }
  }

  async function moveManyToFolder(projectsToMove: TProject[], sectionId: number) {
    const ids = projectsToMove.map((item) => {
      return item.id
    })

    const res = await fetch('/projects_sections/move_many', {
      method: 'POST',
      body: JSON.stringify({
        projectIds: ids,
        sectionId,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to move many to folder`)
    }
    const data = await res.json()
    if (data.result === true && sectionId != section.value?.id) {
      for (let i = 0; i < ids.length; i++) {
        const index = projects.value.findIndex((item) => {
          return ids[i] === item.id
        })
        if (index >= 0) {
          projects.value.splice(index, 1)
          if (projectsPagination.value) {
            projectsPagination.value.total--
          }
        }
      }
    }
  }

  async function regeneratePDF(project: TProject) {
    loader.show('Please wait...')
    const res = await fetch('/html2pdf/regen_pdf', {
      method: 'POST',
      body: JSON.stringify({
        project_id: project.id,
      }),
    })
    loader.hide()
    if (!res.ok) {
      throw new Error(`Failed to regenerate PDF`)
    }
    const data = await res.json()
    return data
  }

  async function getPDFs(project: TProject) {
    const res = await fetch('/html2pdf/getpdfs', {
      method: 'POST',
      body: JSON.stringify({
        project_id: project.id,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to get PDFs`)
    }
    const data = await res.json()
    return data.data
  }

  async function setOrder(project: TProject) {
    const order: { [x: number]: number } = {}
    projects.value.forEach((project, index) => {
      order[project.id] = index + 1
    })
    const res = await fetch('/projects_sections/move', {
      method: 'POST',
      body: JSON.stringify({
        order,
        projectId: project.id,
        sectionId: section.value?.id,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to set order`)
    }
  }

  async function setFoldersOrder() {
    const order: number[] = sections.value.map((item) => {
      return item.id
    })

    const res = await fetch('/projects_sections/reorder', {
      method: 'POST',
      body: JSON.stringify({
        order,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to reorder sections`)
    }
  }

  async function saveAsTemplate(projectId: number, name: string) {
    const res = await fetch('/pr-templates/convertProjectIntoTemplate', {
      method: 'POST',
      body: JSON.stringify({
        name,
        projectId,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to save project as template`)
    }
    return await res.json()
  }

  async function restoreLocalBackup(projectId: number, html: string, css: string) {
    const res = await fetch('/projects/revert-project-local-backup', {
      method: 'POST',
      body: JSON.stringify({
        projectId,
        html,
        css,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to restore a backup`)
    }
    return await res.text()
  }

  async function restoreRemoteBackup(entryId: number) {
    const res = await fetch(`/projects/${entryId}/revert-project`, {
      method: 'POST',
    })
    if (!res.ok) {
      throw new Error(`Failed to restore a backup`)
    }
    return await res.text()
  }

  function goToBuilderAndEdit(slugId: string, slug: string) {
    const isMobile = useMediaQuery('(max-width: 1024px)')

    if (isMobile.value) {
      snackbar.add(
        'Project editing is not available on mobile devices. Please switch to desktop to access editing features',
      )
      return
    }

    localStorage.setItem('backToNewDashboard', 'true')
    location.href = `/app/#/builder/${slugId}/${slug}`
  }

  async function getClientProjects(projectId: number): Promise<TClient[]> {
    return await http.get<TClient[]>('/clients/project/' + projectId)
  }

  async function getPreview(): Promise<TProject | null> {
    try {
      const res = await http.get<{ success: boolean; project: TProject }>('/projects/get-preview')
      if (!res) throw new Error(`Failed to fetch project`)
      return res.project
    } catch {
      return null
    }
  }

  async function usePreview(): Promise<boolean> {
    try {
      await http.get('/projects/use-preview')
      return true
    } catch {
      return false
    }
  }

  async function closePreview(): Promise<boolean> {
    try {
      await http.get('/projects/close-preview')
      return true
    } catch {
      return false
    }
  }

  const myTemplatesFilteredAndSorted = computed(() => {
    const filtered = !myTemplatesSearch.value.length
      ? myTemplates.value
      : myTemplates.value.filter((myTemplate) => {
          return myTemplate.name.toLowerCase().includes(myTemplatesSearch.value.toLowerCase())
        })

    return filtered.sort((a, b) => {
      if (myTemplatesSort.value.name === 'newest') {
        return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      } else if (myTemplatesSort.value.name === 'oldest') {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      } else if (myTemplatesSort.value.name === 'a-z') {
        return a.name.localeCompare(b.name)
      } else if (myTemplatesSort.value.name === 'z-a') {
        return b.name.localeCompare(a.name)
      } else {
        return 0
      }
    })
  })

  return {
    sections,
    sectionsSortBy,
    projects,
    myTemplates,
    myTemplatesFilteredAndSorted,
    myTemplatesSearch,
    myTemplatesSort,
    myTemplatesSortOptions,
    projectsSortBy,
    projectsPagination,
    section,
    rootProjectsCount,
    projectToPublish,
    getClientProjects,
    fetchSections,
    deleteSection,
    deleteSections,
    renameSection,
    fetchProjects,
    fetchProject,
    fetchRecentProjects,
    fetchMyTemplates,
    fetchNextProjects,
    duplicateProject,
    deleteProject,
    deleteProjects,
    renameProject,
    createFolder,
    moveToFolder,
    moveManyToFolder,
    regeneratePDF,
    getPDFs,
    setOrder,
    setFoldersOrder,
    saveAsTemplate,
    restoreLocalBackup,
    restoreRemoteBackup,
    goToBuilderAndEdit,
    getPreview,
    usePreview,
    closePreview,
  }
})
