import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useSnackbarStore } from '@/stores/snackbar'
import { http } from '@/helpers/http'
import { useRouter } from 'vue-router'
import { track } from '@/helpers/mixpanelDes'
import { useRemoteImportStore } from '@/stores/remote-import'
import { useUserStore } from '@/stores/user'
import ChunkUploaderClient from '@/helpers/chunkedUpload'
import {
  ERemoteImportURLStatus,
  ERemoteImportURLType,
  TRemoteImportURL,
  type TUploadedFileInfo,
} from '@/types/remote-imports'
import type { ChunkedUploadModelRecordData } from '@/helpers/chunkedUpload'

export const useLocalImportStore = defineStore('local-import', () => {
  const router = useRouter()
  const importType = ref<ERemoteImportURLType | null>(null)
  const urls = ref<TRemoteImportURL[]>([])
  const dndFile = ref<File | null>(null)
  const user = useUserStore()
  const snack = useSnackbarStore()
  const defaultUploadLimits = {
    docx_import: 0,
    pptx_import: 0,
    audio_import: 0,
    video_import: 0,
    pdf_to_flipbook: 0,
    pdf_import: 0,
    fallback: 0,
  }
  const uploadResume = ref<ChunkedUploadModelRecordData | null>(null)

  const remoteImport = useRemoteImportStore()
  const acceptedFormats = ref<string>(
    'audio/mp4,.wav,audio/aac,audio/x-aac,audio/wav,video/mp4,video/x-ms-asf,video/quicktime,audio/wav,audio/x-wav,audio/mpeg,audio/mp3,audio/x-hx-aac-adts,audio/vnd.dlna.adts,audio/x-m4a,.m4a',
  )

  function detectUrlType(url: string): ERemoteImportURLType {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/
    if (youtubeRegex.test(url)) {
      return ERemoteImportURLType.YouTube
    }

    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/
    if (vimeoRegex.test(url)) {
      return ERemoteImportURLType.Video
    }

    const wistiaRegex = /^(https?:\/\/)?(www\.)?(wistia\.com|wi\.st)\/.+$/
    if (wistiaRegex.test(url)) {
      return ERemoteImportURLType.Video
    }

    const gdocRegex = /^(https?:\/\/)?docs\.google\.com\/document\/.+$/
    if (gdocRegex.test(url)) {
      return ERemoteImportURLType.GDoc
    }

    const urlObj = new URL(url)
    const pathname = urlObj.pathname.toLowerCase()

    if (pathname.endsWith('.docx') || pathname.endsWith('.doc')) {
      return ERemoteImportURLType.DocX
    }

    if (pathname.endsWith('.pdf')) {
      return ERemoteImportURLType.Pdf
    }

    if (
      pathname.endsWith('.mp3') ||
      pathname.endsWith('.wav') ||
      pathname.endsWith('.m4a') ||
      pathname.endsWith('.aac')
    ) {
      return ERemoteImportURLType.Audio
    }

    if (
      pathname.endsWith('.mp4') ||
      pathname.endsWith('.mov') ||
      pathname.endsWith('.avi') ||
      pathname.endsWith('.wmv')
    ) {
      return ERemoteImportURLType.Video
    }
    return ERemoteImportURLType.Web
  }

  const chunkedClient = new ChunkUploaderClient({
    errorCallback: (error: string) => {
      snack.add(error, { type: 'danger' })
      if (dndFile.value) {
        track('project-creating-dnd-failed', {
          name: dndFile.value.name,
          size:
            dndFile.value.size < 1024 * 1024
              ? `${Math.round(dndFile.value.size / 1024)} KiB`
              : `${Math.round(dndFile.value.size / 1024 / 1024)} MiB`,
          type: dndFile.value.type,
        })
      }
      urls.value = []
    },
    progressCallback: (percent: number) => {
      console.log('>>> debug', JSON.stringify(urls.value))
      if (!urls.value || urls.value.length === 0) return
      urls.value[0].status = ERemoteImportURLStatus.Uploading
      if (percent > 100) {
        urls.value[0].progress = 100
      } else {
        urls.value[0].progress = percent
      }
    },
    successCallback: (data: { fileLink: string; fileName: string; uploadedFileInfo?: TUploadedFileInfo }) => {
      urls.value[0].status = ERemoteImportURLStatus.Uploaded
      urls.value[0].fileLink = data.fileLink
      if (['audio', 'video'].includes(importType.value ?? '')) {
        urls.value[0].info = data.uploadedFileInfo
        urls.value[0].status = ERemoteImportURLStatus.ReadyToTranscribe
        return
      }
      if (dndFile.value) {
        track('project-creating-dnd', {
          name: dndFile.value.name,
          size:
            dndFile.value.size < 1024 * 1024
              ? `${Math.round(dndFile.value.size / 1024)} KiB`
              : `${Math.round(dndFile.value.size / 1024 / 1024)} MiB`,
          type: dndFile.value.type,
        })
      }
      remoteImport.importFile(true, data.fileName, urls.value[0])
    },
    uploadLimits: user.publicConfig?.upload_limits || defaultUploadLimits,
    importModulesMap: user.publicConfig?.import_module_map || {},
  })

  async function resume(resumeData: ChunkedUploadModelRecordData, file: File) {
    const buffer = await chunkedClient.getChunkFromBlob(file, resumeData.last_complete_chunk).arrayBuffer()
    if (file.size !== resumeData.size || chunkedClient.calcHash(buffer) !== resumeData.last_complete_chunk_checksum) {
      urls.value.pop()
      snack.add('Upload resume error: wrong file selected')
      return
    }
    chunkedClient.resumeFrom(resumeData, file)
    uploadResume.value = null
  }

  function addFiles(files: File[] | null) {
    if (importType.value !== null && files && files.length > 0) {
      files.forEach((f) => importType.value && urls.value.push(new TRemoteImportURL(importType.value, '', f)))
    } else {
      throw new Error(`Import type not initialized`)
    }

    if (!acceptedFormats.value.split(',').includes(files[0].type)) {
      urls.value.pop()
      snack.add('Wrong file type selected')
      return
    }

    if (uploadResume.value) {
      resume(uploadResume.value, files[0])
    } else {
      chunkedClient.startUpload(files[0], importType.value)
    }
  }

  async function init(type: ERemoteImportURLType, accept?: string) {
    if (accept) {
      acceptedFormats.value = accept
    }
    await user.getPublicConfig()
    chunkedClient.uploadLimits = user.publicConfig?.upload_limits || defaultUploadLimits
    chunkedClient.importModulesMap = user.publicConfig?.import_module_map || {}
    importType.value = type
    await chunkedClient.checkQueue(
      importType.value.toLocaleLowerCase(),
      (resume: ChunkedUploadModelRecordData | null) => {
        if (resume && resume.id !== '0') {
          uploadResume.value = resume
        }
      },
    )
  }

  function removeUrlOrFile(index: number) {
    chunkedClient.cancelUpload()
    urls.value.splice(index, 1)
  }

  async function flushQueue() {
    chunkedClient.flushQueue(importType.value?.toLocaleLowerCase() || '')
    uploadResume.value = null
  }

  async function detectUpload(files: File[] | null) {
    if (!files) {
      return
    }
    if (files.length > 1) {
      snack.add('You can drop only one file')
      return
    }

    await http.get(`/chunked_upload/flush_queue`, {}, true)
    if (files[0].type.includes('video/')) {
      dndFile.value = files[0]
      router.push('/creator/ebook/from-video')
      return
    }

    if (files[0].type.includes('audio/')) {
      dndFile.value = files[0]
      router.push('/creator/ebook/from-podcast')
      return
    }

    const typeMap: { [key: string]: string } = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '/creator/ebook/from-docx',
      'application/pdf': '/creator/ebook/from-pdf',
    }

    if (typeMap[files[0].type]) {
      dndFile.value = files[0]
      router.push(typeMap[files[0].type])
      return
    }
    snack.add('Unknown file type', { type: 'danger' })
  }

  return {
    urls,
    detectUrlType,
    dndFile,
    importType,
    init,
    flushQueue,
    addFiles,
    chunkedClient,
    uploadResume,
    removeUrlOrFile,
    detectUpload,
  }
})
