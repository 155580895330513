<script setup lang="ts">
  import { useAgencyStore } from '@/stores/agency'
  import { isProduction } from '@/helpers/envCheck'

  import DesNavigationItem from '@/components/navigation/DesNavigationItem.vue'

  const { canManageUsers, canManageClients, canEditTemplates } = useAgencyStore()
  const visible = !isProduction()
</script>

<template>
  <DesNavigationItem to="/" icon="fa-light fa-grid-2" name="Home" />
  <DesNavigationItem to="/projects/ebooks" icon="fa-light fa-folder" name="Projects" v-des-tour="`menu-projects`" />
  <DesNavigationItem
    to="/docs"
    icon="fa-light fa-file-lines"
    name="Docs"
    v-des-tour="`menu-docs`"
    postfix="(Prev. Drafts)"
  />
  <DesNavigationItem to="/media" icon="fa-light fa-image" name="Media" v-des-tour="`menu-media`" />
  <DesNavigationItem
    v-if="visible"
    to="/landing-pages"
    icon="fa-light fa-globe-pointer"
    name="Landing pages"
    v-des-tour="`menu-media`"
  />
  <DesNavigationItem v-if="canEditTemplates()" to="/my-templates" icon="fa-light fa-palette" name="Template editor" />
  <template v-if="canManageUsers() || canManageClients()">
    <DesNavigationItem to="/agency" icon="fa-light fa-briefcase" name="Agency" />
  </template>
</template>
