import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSnackbarStore } from '@/stores/snackbar'
import { useUserStore } from '@/stores/user'
import { track } from '@/helpers/mixpanelDes'

export const useFeedbackStore = defineStore('feedback', () => {
  const snack = useSnackbarStore()
  const user = useUserStore()
  const componentShown = ref<boolean>(false)

  async function submitFeedback(rate: 1 | -1 | null, description: string): Promise<boolean> {
    if (rate === null) {
      snack.add('Select thumb up or thumb down first')
      return false
    }

    await user.saveExtraSetting(user.userSettingLabels.NEW_DASH_FEEDBACK_SENT, '1')
    await updateSettingValue(1, user.userSettingLabels.NEW_DASH_FEEDBACK_SENT)

    track('new-design-feedback', {
      rate,
      description,
    })

    snack.add('Thank you, feedback sent', { type: 'success' })

    setTimeout(() => (componentShown.value = false), 5000)

    return true
  }

  async function updateSettingValue(setting: number, label: number) {
    if (!user.extraSettings) {
      return
    }

    const extraSetting = user.extraSettings.find((obj) => obj.setting_key === label)
    if (extraSetting?.value) {
      extraSetting.value = setting.toString()
    } else {
      await user.getExtraSettings()
      await updateSettingValue(setting, label)
    }
  }

  async function countEntries(): Promise<void> {
    const alreadySent: string = await user.getExtraSettingByKey(user.userSettingLabels.NEW_DASH_FEEDBACK_SENT)
    const cancelClicked: number = parseInt(
      await user.getExtraSettingByKey(user.userSettingLabels.NEW_DASH_CANCEL_COUNT),
    )

    if (alreadySent === '1' || (!isNaN(cancelClicked) && cancelClicked >= 3)) {
      return
    }

    let setting: number | string = await user.getExtraSettingByKey(user.userSettingLabels.NEW_DASH_VISIT_COUNT)
    if (!setting || setting === '') {
      await user.saveExtraSetting(user.userSettingLabels.NEW_DASH_VISIT_COUNT, '1')
      setting = 1
    } else {
      setting = parseInt(setting) + 1
      await user.saveExtraSetting(user.userSettingLabels.NEW_DASH_VISIT_COUNT, setting.toString())
    }

    await updateSettingValue(setting, user.userSettingLabels.NEW_DASH_VISIT_COUNT)

    componentShown.value = setting > 10
    return
  }

  async function resetEntryCount() {
    await user.saveExtraSetting(user.userSettingLabels.NEW_DASH_VISIT_COUNT, '1')
    await updateSettingValue(1, user.userSettingLabels.NEW_DASH_VISIT_COUNT)

    const cancelClicked: number = parseInt(
      await user.getExtraSettingByKey(user.userSettingLabels.NEW_DASH_CANCEL_COUNT),
    )
    const newVal: number = isNaN(cancelClicked) ? 1 : cancelClicked + 1
    await user.saveExtraSetting(user.userSettingLabels.NEW_DASH_CANCEL_COUNT, newVal.toString())
    await updateSettingValue(newVal, user.userSettingLabels.NEW_DASH_CANCEL_COUNT)

    componentShown.value = false
  }

  return { submitFeedback, countEntries, resetEntryCount, componentShown }
})
