export function getTimeDifference(dateString: string): string {
  const pastDate: Date = new Date(dateString)
  const currentDate: Date = new Date()
  const timeDifference: number = currentDate.getTime() - pastDate.getTime()

  const minutesDifference: number = Math.floor(timeDifference / 1000 / 60)

  if(minutesDifference < 1) {
    const secondsDifference: number = Math.floor(timeDifference / 1000)
    return `${secondsDifference} ${getSecondsWord(secondsDifference)} ago`
  }

  if(minutesDifference < 60) {
    return `${minutesDifference} ${getMinutesWord(minutesDifference)} ago`
  }

  const hoursDifference: number = Math.floor(minutesDifference / 60)
  if(hoursDifference < 24) {
    return `${hoursDifference} ${getHoursWord(hoursDifference)} ago`
  }

  const daysDifference: number = Math.floor(hoursDifference / 24)
  if(daysDifference < 31) {
    return `${daysDifference} ${getDaysWord(daysDifference)} ago`
  }

  const monthsDifference: number = Math.floor(daysDifference / 30.5)
  if(monthsDifference < 12) {
    return `${monthsDifference} ${getMonthsWord(monthsDifference)} ago`
  }

  const yearsDifference: number = Math.floor(monthsDifference / 12)
  return `${yearsDifference} ${getYearsWord(yearsDifference)} ago`
}

function getSecondsWord(seconds: number): string {
  return seconds === 1 ? "second" : "seconds"
}

function getMinutesWord(minutes: number): string {
  return minutes === 1 ? "minute" : "minutes"
}

function getHoursWord(hours: number): string {
  return hours === 1 ? "hour" : "hours"
}

function getDaysWord(days: number): string {
  return days === 1 ? "day" : "days"
}

function getMonthsWord(months: number): string {
  return months === 1 ? "month" : "months"
}

function getYearsWord(years: number): string {
  return years === 1 ? 'year' : 'years'
}

export function formatUTC_DateToLocal(dateString: string): string {
  // Check if the date string is in the format 'YYYY-MM-DD HH:MM:SS'
  if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(dateString)) {
    dateString = dateString.replace(' ', 'T') + 'Z'
  }
  // If the string is already in ISO format but missing Z, add it
  else if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/.test(dateString) && !dateString.endsWith('Z')) {
    dateString += 'Z'
  }

  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }
  return new Intl.DateTimeFormat('en-GB', options).format(date)
}
